<style scoped>


h2 ,h1 ,h3 ,  h4{
    color:white;
}
.card card-body {
    display: flex;
    overflow-x: auto;
}

</style>
<script>
// import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import Multiselect from "vue-multiselect";
// import DatePicker from "vue2-datepicker";
import { Carousel, Slide } from "vue-carousel";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "บอร์ดสถานะงานซ่อม (แสดงโฆษณา)",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // Layout,
    // PageHeader,
    // Multiselect,
    // DatePicker,
    Carousel,
    Slide,
  },
  data() {
    return {
      title: "บอร์ดสถานะงานซ่อม (แสดงโฆษณา)",
      items: [
        {
          text: "บริการ",
        },
        {
          text: "บอร์ดสถานะงานซ่อม (แสดงโฆษณา)",
          active: true,
        },
      ],
      
      
      loading: undefined,
      form: {},
      branchId: "",
      
      localDataBranch:[], //เป็น-array Id
      localData:[],
      DateSearch: "",
      rowSlot: [],
      rowStatusBoard:[],
      countRowSlot: "",
      colSp: 4,
      fslot1: false,
      Col1: false,
      nowTime: '',

      timer: '',
      cPARK:"",
      cCIN:"",
      cCOUT:"",
      cSW:"",
      cFW:"",
      cSP:"",
      cFP:"",
      cOUT:"",
    };
  },
  computed: {},
  mounted(){
    this.nowTimes();
  },

  created() {
    this.getNow();
    this.nowTimes();
    this.getLocalData();
    this.getDataStatusBoard();
    
  },

  methods: {
    getLocalData(){
      
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchId = user.branchId;
      const localDataBranch=[];
      this.localData.forEach( item => {
        localDataBranch.push(item.branchId)
      })
      
      this.localDataBranch = localDataBranch;
      // console.log(user);
    

    },
    
    getDataStatusBoard: function() {
      this.loading = true;
      useNetw
        .get("api/status-board", {
          params: {
            branchId: this.branchId,
            date: this.DateSearch,
            // date: "2021-07-17"
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          
          this.rowStatusBoard = response.data.data;
          // console.log("rowStatusBoard ====== > ", this.rowStatusBoard);
          this.getTotalStatus();
          
        })
        .catch((err) => {
          if (err.response.status === 401) {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify('กรุณาเลือกสาขา'),
            appConfig.swal.type.error
          );
          this.$router.push("/login");
          }
        })
        .then(() => {
          this.loading = false;
        });
    },

    getTotalStatus(){ 
      this.cPARK = this.rowStatusBoard.filter(x => x.status == 'PARK').length;
      this.cCIN = this.rowStatusBoard.filter(x => x.status == 'CIN').length;
      this.cCOUT = this.rowStatusBoard.filter(x => x.status == 'COUT').length;
      this.cSW = this.rowStatusBoard.filter(x => x.status == 'SW').length;
      this.cFW = this.rowStatusBoard.filter(x => x.status == 'FW').length;
      this.cSP = this.rowStatusBoard.filter(x => x.status == 'SP').length;
      this.cFP = this.rowStatusBoard.filter(x => x.status == 'FP').length;
      this.cOUT = this.rowStatusBoard.filter(x => x.status == 'OUT').length;

      // console.log(this.cCOUT);
      // console.log('PARK = ',this.cPARK);
      // console.log('CIN = ',this.cCIN);
    },
    getNow: function() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1 > 9 ? "" : "0") +
        (today.getMonth() + 1) +
        "-" +
        (today.getDate() > 9 ? "" : "0") +
        today.getDate();

      this.DateSearch = date;
      // console.log(this.DateSearch);
    },
    timeFormate(timeStamp) {
      let hh =new Date(timeStamp).getHours() < 10? "0" + new Date(timeStamp).getHours(): new Date(timeStamp).getHours();
      let mm =new Date(timeStamp).getMinutes() < 10? "0" + new Date(timeStamp).getMinutes(): new Date(timeStamp).getMinutes();
        this.nowTime =  hh + ":" + mm;
    },
 
    nowTimes(){
      this.timeFormate(new Date());
      setInterval(this.nowTimes,30*1000);
    },

  },
  middleware: "authentication",
};
</script>

<template>
  <!-- <Layout> -->
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-12">
        <div class="card text-white" style="background-color:black">
          <div class="card-body">
            <b-skeleton-wrapper :loading="loading">
              <template #loading>
                <b-skeleton-table
                  :rows="5"
                  :columns="6"
                  :table-props="{ bordered: false, striped: true }"
                  animation="throb"
                ></b-skeleton-table>
              </template>
              <div class="row">
                <div class="col-md-10">
              <!-- <h4 class="card-title">สถานะรถเข้าศุูนย์บริการ</h4> -->
              </div>
              <div class="col-md-2 text-end">
                <router-link :to="{ name: 'home' }">
                  <a >กลับสู่หน้าหลัก</a>
                </router-link>
                
              </div>
              </div>
              <hr />
              <!-- <div class="row"> -->
                <!-- <div class="col-md-6">
                  <div class="mb-3 position-relative">
                    <code> * </code>
                    <label for="branchId">สาขา</label><br />
                    <multiselect
                      v-model="branchId"
                      :options="optionsBranch"
                      label="branNameTh"
                    >
                    </multiselect>
                  </div>
                </div> -->

                <!-- <div class="col-md-3">
                  <div class="mb-3 position-relative">
                    <code> * </code>
                    <label for="birthDate">วันที่</label>

                    <date-picker
                      v-model="DateSearch"
                      :first-day-of-week="1"
                      format="YYYY-MM-DD"
                      value-type="format"
                      lang="en"
                    >
                    </date-picker>
                  </div>
                </div> -->

                <!-- <div class="col-md-1">
                  <label for="Search">-</label><br />
                  <button class="btn btn-success" @click="getDatStatusBoard()">
                    ค้นหา
                  </button>
                </div> -->
                <!-- <div class="col-md-2">
                  <label for="Search">-</label><br />
                  <button class="btn btn-secondary">กลับสู่หน้าหลัก</button>
                </div> -->

                <!-- <code>
                  slotId = ข้อมูลที่อยู่ใน fSlot อีกที <br />
                  pslotId = ช่องจอด มี 4 fslotId<br />
                  fslotId = ข้อมูลเวลาที่อยู่ใน pSlot อีกที เช่น fslotId:1 คือ
                  ช่วงเวลา 08.00-10.00
                </code> -->
              <!-- </div> -->

              
              <hr />
            
            <div class="row">
                <div class="col-md-6">
                    <div class="card text-white" style="background-color:black">
                    <div class="card-body text-white">
                        <h1 style="color:white;"> 
                          <b> 
                            Status Board, <br>
                            <marquee direction="left">
                            จำนวนรถเข้าบริการ :  {{ rowStatusBoard.length }} คัน 
                            ( walk in :  0 คัน  /  Booking : {{rowStatusBoard.length}} คัน ) ,
                            สถานะ ( 
                              เข้าจอด {{ cPARK }} คัน  /
                              กำลังซ่อม  {{ cCIN }} คัน /
                              กำลังล้าง {{ cFW }} คัน  /
                              ส่งมอบ {{ cOUT }} คัน /
                            ) 
                            </marquee>



                          </b>
                        </h1> 
                        <!-- <h2> Suriya IT </h2> -->
                        <p class="card-title-desc">
                        สถานะรถเข้าศุูนย์บริการ
                        </p>
                        <b-carousel
                        style="text-shadow: 0px 0px 2px #000"
                        controls
                        indicators
                        >
                        <b-carousel-slide
                            :img-src="require('@/assets/images/small/img-8.jpg')"
                        ></b-carousel-slide>
                        <b-carousel-slide
                            :img-src="require('@/assets/images/small/img-9.jpg')"
                        ></b-carousel-slide>
                        
                        </b-carousel>
                    </div>
                    </div>
                </div>
            
            <div class="col-md-6">
                <h1 class="text-end">{{nowTime}}</h1><br>
                <br>
                <h4><b>สถานะขณะนี้</b></h4>
                <div class="table-responsive " >
                <table class="table mb-0 text-white">
                  <thead style="color:white;">
                    
                    <tr>
                      <th  class="text-center"> <h4><b>ทะเบียนรถ</b></h4> </th>
                      <th  class="text-center"> <h4><b>สถานะรถ</b> </h4> </th>
                      <th  class="text-center"> <h4><b>เวลาเสร็จ</b></h4> </th>
                      
                    </tr>
                    
                  </thead>
                  <tbody >
                    <tr v-for="(itemSTB, index) in rowStatusBoard" :key="index" >
                      <td class="text-center">
                        <h4><b>{{ itemSTB.licensePlate }} </b></h4>
                        {{ itemSTB.modelNameTh }}
                      </td>
                    <td v-if="itemSTB.status === 'PARK'" class="text-center">  
                        <h4>เข้าจอด</h4>
                    </td>
                    <td v-else-if="itemSTB.status === 'SW'" class="text-center">
                        <h4>กำลังล้าง</h4>
                    </td>
                    <td v-else-if="itemSTB.status === 'FW'" class="text-center">
                        <h4>ล้างเสร็จ</h4>
                    </td>
                    <td v-else-if="itemSTB.status === 'COUT'" class="text-center">
                        <h4>ส่งมอบแล้ว</h4>
                    </td>
                        
                      <th class="text-center">
                          
                        <h4><b>{{itemSTB.expectFinishTime}}</b></h4>
                      </th>
              
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            

            </div> 
            <!-- End .row -->

            <!-- <div class="row" v-for="(itemSTB, index) in rowStatusBoard" :key="index">
                {{ itemSTB.licensePlate }}
            </div> -->
            <nav class="navbar fixed-bottom navbar-dark bg-dark" style="height:150px;">
                <div class="row mx-auto"><h4>รถนัดหมายวันนี้</h4></div><br>
                <br>
                <div class="row">
                <div class="hori-timeline">
                    <carousel
                        ref="carousel"
                        class="events navs-carousel"
                        id="timeline-carousel"
                        :navigation-enabled="false"
                        :pagination-enabled="false"
                        :perPageCustom="[
                        [480, 2],
                        [768, 4],
                        ]"
                        
                    >
                        <slide v-for="(itemSTB, index) in rowStatusBoard" :key="index">
                            <div class="col-md-11">
                            <div class="card card-body">
                                <h3 class="text-center" style="color:black;">{{itemSTB.licensePlate}}</h3> 
                                <h5 class="text-center" style="color:black;">{{itemSTB.modelNameTh}}</h5> 
                            </div>
                            </div>
                        </slide>
                        
                    </carousel>
            </div>
            </div>
            </nav>
              
            </b-skeleton-wrapper>
          </div>
        </div>
      </div>
    </div>
  <!-- </Layout> -->
</template>
